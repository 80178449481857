<template>
  <div class="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white">
    <div class="container mx-auto px-4 py-12">
      <h1 class="text-5xl font-bold mb-8 text-yellow-400 font-serif text-center">
        Découvrez La Rochelle
      </h1>
      
      <div class="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16 items-center">
        <div class="relative group">
          <img 
            src="/placeholder.svg?height=600&width=800"
            alt="Vue du Vieux-Port de La Rochelle" 
            class="w-full h-96 object-cover rounded-lg shadow-lg transition-transform duration-300 group-hover:scale-105"
          />
          <div class="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <p class="text-white text-xl font-semibold">Le Vieux-Port, cœur historique de La Rochelle</p>
          </div>
        </div>
        <div>
          <h2 class="text-3xl font-semibold mb-6 text-yellow-400 font-serif">Une ville riche d'histoire</h2>
          <p class="text-gray-300 mb-4 text-lg leading-relaxed">
            La Rochelle, joyau de la côte atlantique française, est une ville au passé millénaire. Fondée au Xe siècle, elle s'est rapidement imposée comme un port important, jouant un rôle crucial dans l'histoire de France.
          </p>
          <p class="text-gray-300 text-lg leading-relaxed">
            Aujourd'hui, La Rochelle est reconnue pour son patrimoine architectural exceptionnel, son engagement écologique et sa qualité de vie enviable. Elle attire chaque année des millions de visiteurs, séduits par son charme intemporel et son dynamisme culturel.
          </p>
          <router-link 
            to="/histoire" 
            class="mt-4 inline-block bg-yellow-400 text-black px-6 py-2 rounded-full text-lg font-semibold hover:bg-yellow-500 transition duration-300"
          >
            En savoir plus sur l'histoire
          </router-link>
        </div>
      </div>

      <div class="bg-gray-800 rounded-lg shadow-xl p-8 mb-16 transform hover:scale-105 transition-transform duration-300">
        <h2 class="text-3xl font-semibold mb-6 text-yellow-400 font-serif">Notre mission</h2>
        <p class="text-gray-300 mb-6 text-lg leading-relaxed">
          VIVRE LA ROCHELLE est bien plus qu'un simple site web. C'est une plateforme dédiée à tous ceux qui aiment La Rochelle, qu'ils soient résidents de longue date, nouveaux arrivants ou visiteurs.
        </p>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div v-for="(mission, index) in missions" :key="index" class="flex items-start space-x-4">
            <div class="bg-yellow-400 p-2 rounded-full">
              <component :is="mission.icon" class="w-6 h-6 text-black" />
            </div>
            <div>
              <h3 class="text-xl font-semibold mb-2 text-white">{{ mission.title }}</h3>
              <p class="text-gray-300">{{ mission.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-8 mb-16">
        <div 
          v-for="(stat, index) in cityStats" 
          :key="index" 
          class="bg-gray-800 rounded-lg shadow-xl p-6 text-center transform hover:scale-105 transition-transform duration-300"
        >
          <h3 class="text-4xl font-bold text-yellow-400 mb-2">{{ stat.value }}</h3>
          <p class="text-gray-300 text-lg">{{ stat.label }}</p>
        </div>
      </div>

      <div class="bg-gray-800 rounded-lg shadow-xl p-8 mb-16">
        <h2 class="text-3xl font-semibold mb-6 text-yellow-400 font-serif">Explorez La Rochelle</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div 
            v-for="(place, index) in places" 
            :key="index"
            class="bg-gray-700 rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300"
          >
            <img :src="place.image" :alt="place.name" class="w-full h-48 object-cover" />
            <div class="p-4">
              <h3 class="text-xl font-semibold mb-2 text-yellow-400">{{ place.name }}</h3>
              <p class="text-gray-300 text-sm mb-4">{{ place.description }}</p>
              <router-link 
                :to="place.link" 
                class="inline-block bg-yellow-400 text-black px-4 py-2 rounded-md text-sm font-semibold hover:bg-yellow-500 transition duration-300"
              >
                En savoir plus
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-gray-800 rounded-lg shadow-xl p-8">
        <h2 class="text-3xl font-semibold mb-6 text-yellow-400 font-serif">Contactez-nous</h2>
        <p class="text-gray-300 mb-6 text-lg leading-relaxed">
          Vous avez des questions, des suggestions ou vous souhaitez contribuer à VIVRE LA ROCHELLE ? N'hésitez pas à nous contacter !
        </p>
        <div class="flex justify-center space-x-4">
          <a 
            href="mailto:contact@vivrelarochelle.fr" 
            class="inline-block bg-yellow-400 text-black px-8 py-3 rounded-full text-lg font-semibold hover:bg-yellow-500 transition duration-300 transform hover:scale-105"
          >
            Envoyez-nous un email
          </a>
          <router-link 
            to="/contact" 
            class="inline-block bg-gray-700 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-600 transition duration-300 transform hover:scale-105"
          >
            Formulaire de contact
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Share2, Heart, Leaf, Users } from 'lucide-vue-next'

const missions = ref([
  { 
    icon: Share2, 
    title: 'Partager la culture', 
    description: 'Diffuser la richesse culturelle et historique de La Rochelle.' 
  },
  { 
    icon: Heart, 
    title: 'Informer', 
    description: 'Tenir la communauté au courant des événements et actualités locales.' 
  },
  { 
    icon: Leaf, 
    title: 'Promouvoir l\'écologie', 
    description: 'Mettre en avant les initiatives écologiques de la ville.' 
  },
  { 
    icon: Users, 
    title: 'Créer une communauté', 
    description: 'Rassembler les passionnés de La Rochelle.' 
  }
])

const cityStats = ref([
  { value: '75 000+', label: 'Habitants' },
  { value: '1000+', label: 'Ans d\'histoire' },
  { value: '4.5M', label: 'Visiteurs par an' }
])

const places = ref([
  {
    name: 'Le Vieux-Port',
    image: '/placeholder.svg?height=300&width=500',
    description: 'Cœur historique de la ville, entouré de restaurants et de boutiques.',
    link: '/decouvrir/vieux-port'
  },
  {
    name: 'Les Tours',
    image: '/placeholder.svg?height=300&width=500',
    description: 'Emblèmes de La Rochelle, ces tours médiévales gardent l\'entrée du port.',
    link: '/decouvrir/tours'
  },
  {
    name: 'L\'Aquarium',
    image: '/placeholder.svg?height=300&width=500',
    description: 'Un des plus grands aquariums privés d\'Europe, abritant 12 000 animaux marins.',
    link: '/decouvrir/aquarium'
  },
  {
    name: 'Plage des Minimes',
    image: '/placeholder.svg?height=300&width=500',
    description: 'La plus grande plage de La Rochelle, idéale pour la baignade et les sports nautiques.',
    link: '/decouvrir/plage-minimes'
  }
])
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Playfair+Display:wght@400;700&display=swap');

:deep(body) {
  font-family: 'Lato', sans-serif;
}

:deep(h1), :deep(h2), :deep(h3) {
  font-family: 'Playfair Display', serif;
}
</style>
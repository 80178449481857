<template>
  <header class="bg-black text-white">
    <div class="container mx-auto px-4">
      <div class="flex justify-between items-center py-4">
        <router-link to="/" class="flex items-center">
          <img
            src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/LOGO%20LAROCHEE%20DEF.JPG-XxyphoEhpsta49dlZESoGXDqL7FcOf.jpeg"
            alt="VIVRE LA ROCHELLE Photographie"
            class="h-16 w-auto"
          />
        </router-link>
        <nav class="hidden lg:flex space-x-8">
          <router-link to="/" class="text-white hover:text-yellow-400 transition duration-300">Accueil</router-link>
          <router-link to="/about" class="text-white hover:text-yellow-400 transition duration-300">À propos</router-link>
          <router-link to="/services" class="text-white hover:text-yellow-400 transition duration-300">Nos Services</router-link>
          <router-link to="/gallery" class="text-white hover:text-yellow-400 transition duration-300">Galerie</router-link>
          <router-link to="/actualites-locales" class="text-white hover:text-yellow-400 transition duration-300">Actualités</router-link>
          <div class="relative group">
            <button
              @click="toggleMyCity"
              class="text-white hover:text-yellow-400 transition duration-300 flex items-center"
            >
              My city
              <chevron-down-icon class="ml-1 h-4 w-4" />
            </button>
            <div class="absolute left-0 mt-2 w-48 bg-black rounded-md shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300">
              <router-link to="/activites-plein-air" class="block px-4 py-2 text-sm text-white hover:bg-yellow-400 hover:text-black">Activités</router-link>
              <router-link to="/restaurants-cafes" class="block px-4 py-2 text-sm text-white hover:bg-yellow-400 hover:text-black">Restaurants</router-link>
              <router-link to="/boutiques-locales" class="block px-4 py-2 text-sm text-white hover:bg-yellow-400 hover:text-black">Boutiques</router-link>
              <router-link to="/transports" class="block px-4 py-2 text-sm text-white hover:bg-yellow-400 hover:text-black">Transports</router-link>
              <router-link to="/espaces-verts-plages" class="block px-4 py-2 text-sm text-white hover:bg-yellow-400 hover:text-black">Espaces Verts</router-link>
              <router-link to="/calendrier-evenements" class="block px-4 py-2 text-sm text-white hover:bg-yellow-400 hover:text-black">Événements</router-link>
            </div>
          </div>
          <router-link to="/forum" class="text-white hover:text-yellow-400 transition duration-300">Forum</router-link>
          <router-link to="/contact" class="text-white hover:text-yellow-400 transition duration-300">Contact</router-link>
        </nav>
        <button @click="toggleMenu" class="lg:hidden text-white focus:outline-none">
          <menu-icon v-if="!isMenuOpen" class="h-6 w-6" />
          <x-icon v-else class="h-6 w-6" />
        </button>
      </div>
    </div>
    <div v-if="isMenuOpen" class="lg:hidden bg-black">
      <div class="container mx-auto px-4 py-4">
        <router-link to="/" class="block py-2 text-white hover:text-yellow-400">Accueil</router-link>
        <router-link to="/about" class="block py-2 text-white hover:text-yellow-400">À propos</router-link>
        <router-link to="/services" class="block py-2 text-white hover:text-yellow-400">Nos Services</router-link>
        <router-link to="/gallery" class="block py-2 text-white hover:text-yellow-400">Galerie</router-link>
        <router-link to="/actualites-locales" class="block py-2 text-white hover:text-yellow-400">Actualités</router-link>
        <button
          @click="toggleMyCity"
          class="flex items-center justify-between w-full py-2 text-white hover:text-yellow-400"
        >
          My city
          <chevron-down-icon class="h-4 w-4" />
        </button>
        <div v-if="isMyCityOpen" class="pl-4">
          <router-link to="/activites-plein-air" class="block py-2 text-white hover:text-yellow-400">Activités</router-link>
          <router-link to="/restaurants-cafes" class="block py-2 text-white hover:text-yellow-400">Restaurants</router-link>
          <router-link to="/boutiques-locales" class="block py-2 text-white hover:text-yellow-400">Boutiques</router-link>
          <router-link to="/transports" class="block py-2 text-white hover:text-yellow-400">Transports</router-link>
          <router-link to="/espaces-verts-plages" class="block py-2 text-white hover:text-yellow-400">Espaces Verts</router-link>
          <router-link to="/calendrier-evenements" class="block py-2 text-white hover:text-yellow-400">Événements</router-link>
        </div>
        <router-link to="/forum" class="block py-2 text-white hover:text-yellow-400">Forum</router-link>
        <router-link to="/contact" class="block py-2 text-white hover:text-yellow-400">Contact</router-link>
      </div>
    </div>
  </header>
</template>

<script setup>
import { ref } from 'vue'
import { MenuIcon, XIcon, ChevronDownIcon } from 'lucide-vue-next'

const isMenuOpen = ref(false)
const isMyCityOpen = ref(false)

const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value
}

const toggleMyCity = () => {
  isMyCityOpen.value = !isMyCityOpen.value
}
</script>

<style scoped>
/* Vous pouvez ajouter des styles spécifiques ici si nécessaire */
</style>
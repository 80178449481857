<template>
  <div class="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white">
    <div class="container mx-auto px-4 py-12">
      <h1 class="text-5xl font-bold mb-8 text-yellow-400 font-serif text-center">
        Nos Services
      </h1>

      <p class="text-xl text-center mb-12 text-gray-300">
        Découvrez les services offerts par la ville de La Rochelle et notre plateforme pour améliorer votre expérience de vie dans notre belle cité.
      </p>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
        <div v-for="(service, index) in services" :key="index" class="bg-gray-800 rounded-lg shadow-xl p-6 flex flex-col justify-between transform hover:scale-105 transition-all duration-300">
          <div>
            <div class="flex items-center justify-center w-16 h-16 bg-yellow-400 rounded-full mb-4 mx-auto">
              <component :is="service.icon" class="w-8 h-8 text-black" />
            </div>
            <h2 class="text-2xl font-semibold mb-4 text-yellow-400 text-center">{{ service.title }}</h2>
            <p class="text-gray-300 mb-4">{{ service.description }}</p>
          </div>
          <button @click="showServiceDetails(service)" class="inline-block bg-yellow-400 text-black px-6 py-2 rounded-full text-center font-semibold hover:bg-yellow-500 transition duration-300">
            En savoir plus
          </button>
        </div>
      </div>

      <div class="bg-gray-800 rounded-lg shadow-xl p-8 mb-16">
        <h2 class="text-3xl font-semibold mb-6 text-yellow-400 font-serif text-center">Services en vedette</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div v-for="(feature, index) in featuredServices" :key="index" class="bg-gray-700 rounded-lg p-6">
            <h3 class="text-xl font-semibold mb-4 text-yellow-400">{{ feature.title }}</h3>
            <p class="text-gray-300 mb-4">{{ feature.description }}</p>
            <router-link :to="feature.link" class="text-yellow-400 hover:underline flex items-center">
              Découvrir
              <ArrowRight class="ml-2 w-4 h-4" />
            </router-link>
          </div>
        </div>
      </div>

      <div class="bg-gray-800 rounded-lg shadow-xl p-8">
        <h2 class="text-3xl font-semibold mb-6 text-yellow-400 font-serif text-center">Besoin d'aide ?</h2>
        <p class="text-gray-300 mb-6 text-center">
          Notre équipe est là pour vous aider à trouver les services dont vous avez besoin.
        </p>
        <div class="flex justify-center space-x-4">
          <router-link to="/contact" class="inline-flex items-center bg-yellow-400 text-black px-8 py-3 rounded-full text-lg font-semibold hover:bg-yellow-500 transition duration-300">
            <Mail class="mr-2 w-5 h-5" />
            Nous contacter
          </router-link>
          <router-link to="/faq" class="inline-flex items-center bg-gray-700 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-gray-600 transition duration-300">
            <HelpCircle class="mr-2 w-5 h-5" />
            FAQ
          </router-link>
        </div>
      </div>
    </div>

    <Teleport to="body">
      <div v-if="selectedService" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div class="bg-gray-800 p-8 rounded-lg max-w-2xl w-full mx-4">
          <h2 class="text-3xl font-semibold mb-4 text-yellow-400">{{ selectedService.title }}</h2>
          <p class="text-gray-300 mb-6">{{ selectedService.fullDescription }}</p>
          <div class="flex justify-end">
            <button @click="closeServiceDetails" class="bg-yellow-400 text-black px-6 py-2 rounded-full font-semibold hover:bg-yellow-500 transition duration-300 flex items-center">
              <X class="mr-2 w-4 h-4" />
              Fermer
            </button>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Bike, Bus, Recycle, Book, Landmark, Users, ArrowRight, Mail, HelpCircle, X, Camera, User } from 'lucide-vue-next'

const selectedService = ref(null)

const services = ref([
  {
    icon: Bike,
    title: 'Mobilité Verte',
    description: 'Découvrez nos services de vélos en libre-service et nos pistes cyclables pour une mobilité écologique.',
    fullDescription: 'La Rochelle s\'engage pour une mobilité durable avec un réseau étendu de pistes cyclables et un service de vélos en libre-service. Profitez de nos 200 km de pistes cyclables, de nos stations de vélos électriques, et participez à nos ateliers de réparation de vélos. Ensemble, réduisons notre empreinte carbone et améliorons la qualité de vie dans notre ville.'
  },
  {
    icon: Bus,
    title: 'Transports Publics',
    description: 'Informations sur les lignes de bus, horaires et tarifs pour se déplacer facilement dans La Rochelle.',
    fullDescription: 'Notre réseau de transports publics couvre l\'ensemble de l\'agglomération de La Rochelle. Avec des bus fréquents, des navettes électriques dans le centre-ville et des bateaux-bus écologiques, nous offrons des solutions de transport adaptées à tous. Consultez nos horaires en temps réel, achetez vos tickets en ligne et profitez de tarifs avantageux pour vos déplacements quotidiens.'
  },
  {
    icon: Recycle,
    title: 'Gestion des Déchets',
    description: 'Tout savoir sur le tri sélectif, le recyclage et les points de collecte dans la ville.',
    fullDescription: 'La Rochelle s\'engage dans une démarche zéro déchet. Découvrez nos guides de tri sélectif, les emplacements de nos points de collecte et nos initiatives de compostage communautaire. Participez à nos ateliers de sensibilisation et apprenez à réduire vos déchets au quotidien. Ensemble, préservons notre environnement et faisons de La Rochelle une ville plus propre et plus verte.'
  },
  {
    icon: Book,
    title: 'Bibliothèques',
    description: 'Accédez à notre réseau de bibliothèques municipales et à nos ressources en ligne.',
    fullDescription: 'Notre réseau de bibliothèques municipales offre un accès à plus de 500 000 documents, des espaces de travail modernes et des événements culturels réguliers. Profitez de notre catalogue en ligne, réservez vos livres à distance et accédez à notre bibliothèque numérique 24h/24. Participez à nos clubs de lecture, ateliers d\'écriture et rencontres avec des auteurs pour enrichir votre vie culturelle.'
  },
  {
    icon: Landmark,
    title: 'Culture et Loisirs',
    description: 'Découvrez les événements culturels, les musées et les activités de loisirs à La Rochelle.',
    fullDescription: 'La Rochelle vibre au rythme de sa vie culturelle riche et variée. Explorez nos musées, galeries d\'art et sites historiques. Assistez à nos festivals de musique, de cinéma et de théâtre. Profitez de nos installations sportives et de loisirs, des parcs et jardins. Consultez notre agenda culturel pour ne rien manquer des événements qui animent notre belle cité tout au long de l\'année.'
  },
  {
    icon: Users,
    title: 'Services Sociaux',
    description: 'Informations sur les aides sociales, le logement et les services aux personnes âgées.',
    fullDescription: 'Nos services sociaux sont là pour accompagner tous les Rochelais dans leurs démarches et leurs besoins. Que vous cherchiez des informations sur les aides au logement, les services de garde d\'enfants, l\'accompagnement des personnes âgées ou en situation de handicap, nous sommes là pour vous guider. Découvrez nos permanences d\'accueil, nos ateliers d\'insertion et nos programmes de soutien communautaire.'
  },
  {
    icon: Camera,
    title: 'Photographie d\'événements',
    description: 'Capturez les moments importants de votre vie à La Rochelle avec nos photographes professionnels.',
    fullDescription: 'Notre service de photographie d\'événements vous permet d\'immortaliser vos moments les plus précieux à La Rochelle. Que ce soit pour un mariage, un anniversaire, une réunion de famille ou un événement d\'entreprise, nos photographes professionnels sauront capturer l\'essence de votre journée. Nous proposons des forfaits adaptés à tous les budgets et tous les types d\'événements, avec des options de livraison numérique et d\'albums photo personnalisés.'
  },
  {
    icon: User,
    title: 'Portraits personnels',
    description: 'Mettez-vous en valeur avec nos séances de portraits professionnels.',
    fullDescription: 'Nos séances de portraits personnels sont conçues pour mettre en valeur votre personnalité et votre meilleur profil. Que vous ayez besoin d\'une photo professionnelle pour votre CV ou vos réseaux sociaux, ou que vous souhaitiez simplement avoir de belles photos de vous-même ou de votre famille, nos photographes expérimentés sauront vous mettre à l\'aise et capturer votre essence. Nous proposons des séances en studio ou en extérieur, avec des options de retouche et d\'impression de haute qualité.'
  }
])

const featuredServices = ref([
  {
    title: 'Yélo, le service de mobilité',
    description: 'Vélos, bus, bateaux électriques... Découvrez toutes les options de transport écologique à La Rochelle.',
    link: '/services/yelo'
  },
  {
    title: 'La Rochelle Tourisme',
    description: 'Planifiez votre séjour à La Rochelle avec notre office de tourisme en ligne.',
    link: '/tourisme'
  }
])

const showServiceDetails = (service) => {
  selectedService.value = service
}

const closeServiceDetails = () => {
  selectedService.value = null
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Playfair+Display:wght@400;700&display=swap');

:deep(body) {
  font-family: 'Lato', sans-serif;
}

:deep(h1), :deep(h2), :deep(h3) {
  font-family: 'Playfair Display', serif;
}
</style>
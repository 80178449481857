<template>
    <button
      :class="[
        'px-4 py-2 rounded-md transition-colors duration-200',
        variantClasses,
        { 'opacity-50 cursor-not-allowed': disabled }
      ]"
      :disabled="disabled"
      @click="handleClick"
    >
      <slot></slot>
    </button>
  </template>
  
  <script setup>
  import { computed } from 'vue'
  
  const props = defineProps({
    variant: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'muted'].includes(value)
    },
    disabled: {
      type: Boolean,
      default: false
    }
  })
  
  const emit = defineEmits(['click'])
  
  const variantClasses = computed(() => {
    switch (props.variant) {
      case 'primary':
        return 'bg-primary text-primary-foreground hover:bg-primary/90'
      case 'secondary':
        return 'bg-secondary text-secondary-foreground hover:bg-secondary/90'
      default:
        return 'bg-muted text-muted-foreground hover:bg-muted/90'
    }
  })
  
  const handleClick = (event) => {
    if (!props.disabled) {
      emit('click', event)
    }
  }
  </script>
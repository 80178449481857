<template>
  <div class="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-4xl mx-auto">
      <h1 class="text-4xl font-bold text-center text-yellow-400 mb-8">Contactez-nous</h1>
      
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div class="bg-gray-800 rounded-lg shadow-xl p-8">
          <form @submit.prevent="submitForm" class="space-y-6">
            <div>
              <label for="name" class="block text-sm font-medium text-gray-300">Nom</label>
              <input 
                id="name" 
                v-model="form.name" 
                type="text" 
                required 
                :class="['mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-yellow-500 focus:ring-yellow-500', {'border-red-500': errors.name}]"
                @blur="validateField('name')"
              >
              <p v-if="errors.name" class="mt-1 text-sm text-red-500">{{ errors.name }}</p>
            </div>
            
            <div>
              <label for="email" class="block text-sm font-medium text-gray-300">Email</label>
              <input 
                id="email" 
                v-model="form.email" 
                type="email" 
                required 
                :class="['mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-yellow-500 focus:ring-yellow-500', {'border-red-500': errors.email}]"
                @blur="validateField('email')"
              >
              <p v-if="errors.email" class="mt-1 text-sm text-red-500">{{ errors.email }}</p>
            </div>
            
            <div>
              <label for="subject" class="block text-sm font-medium text-gray-300">Sujet</label>
              <select 
                id="subject" 
                v-model="form.subject" 
                required 
                :class="['mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-yellow-500 focus:ring-yellow-500', {'border-red-500': errors.subject}]"
                @blur="validateField('subject')"
              >
                <option value="">Choisissez un sujet</option>
                <option value="general">Renseignement général</option>
                <option value="services">Services municipaux</option>
                <option value="events">Événements</option>
                <option value="complaint">Réclamation</option>
                <option value="other">Autre</option>
              </select>
              <p v-if="errors.subject" class="mt-1 text-sm text-red-500">{{ errors.subject }}</p>
            </div>
            
            <div>
              <label for="message" class="block text-sm font-medium text-gray-300">Message</label>
              <textarea 
                id="message" 
                v-model="form.message" 
                rows="4" 
                required 
                :class="['mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-yellow-500 focus:ring-yellow-500', {'border-red-500': errors.message}]"
                @blur="validateField('message')"
              ></textarea>
              <p v-if="errors.message" class="mt-1 text-sm text-red-500">{{ errors.message }}</p>
            </div>
            
            <div class="flex items-center">
              <input 
                id="privacy" 
                v-model="form.privacy" 
                type="checkbox" 
                required 
                class="h-4 w-4 rounded border-gray-300 text-yellow-600 focus:ring-yellow-500"
              >
              <label for="privacy" class="ml-2 block text-sm text-gray-300">
                J'accepte la politique de confidentialité
              </label>
            </div>
            
            <div>
              <button 
                type="submit" 
                :disabled="isSubmitting || !isFormValid"
                :class="['w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 transition-all duration-200 ease-in-out', {'opacity-50 cursor-not-allowed': isSubmitting || !isFormValid}]"
              >
                <span v-if="isSubmitting" class="flex items-center">
                  <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Envoi en cours...
                </span>
                <span v-else>Envoyer</span>
              </button>
            </div>
          </form>
          
          <div v-if="submitStatus" :class="['mt-4 p-4 rounded-md transition-all duration-500 ease-in-out', submitStatus === 'success' ? 'bg-green-800' : 'bg-red-800']">
            {{ statusMessage }}
          </div>
        </div>
        
        <div class="space-y-8">
          <div class="bg-gray-800 rounded-lg shadow-xl p-8">
            <h2 class="text-2xl font-bold text-yellow-400 mb-4">Informations de contact</h2>
            <div class="space-y-4">
              <p><strong>Adresse :</strong> Hôtel de Ville, Place de l'Hôtel de Ville, 17000 La Rochelle</p>
              <p><strong>Téléphone :</strong> 05 46 51 51 51</p>
              <p><strong>Email :</strong> info@vivrelarochelle.fr</p>
              <p><strong>Horaires d'ouverture :</strong></p>
              <ul class="list-disc list-inside pl-4">
                <li>Lundi - Vendredi : 8h30 - 17h00</li>
                <li>Samedi : 9h00 - 12h00</li>
                <li>Dimanche : Fermé</li>
              </ul>
            </div>
          </div>
          
          <div class="bg-gray-800 rounded-lg shadow-xl p-8">
            <h2 class="text-2xl font-bold text-yellow-400 mb-4">Notre emplacement</h2>
            <div class="aspect-w-16 aspect-h-9">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2763.4675112920824!2d-1.1539305842089!3d46.16045397911556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48015383c9253d75%3A0x405d39260ee9640!2sH%C3%B4tel%20de%20Ville!5e0!3m2!1sfr!2sfr!4v1652893648167!5m2!1sfr!2sfr" 
                width="100%" 
                height="100%" 
                style="border:0;" 
                allowfullscreen="" 
                loading="lazy" 
                referrerpolicy="no-referrer-when-downgrade"
                title="Carte de l'Hôtel de Ville de La Rochelle"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed } from 'vue'

const form = reactive({
  name: '',
  email: '',
  subject: '',
  message: '',
  privacy: false
})

const errors = reactive({
  name: '',
  email: '',
  subject: '',
  message: ''
})

const isSubmitting = ref(false)
const submitStatus = ref(null)
const statusMessage = ref('')

const validateField = (field) => {
  errors[field] = ''
  switch (field) {
    case 'name':
      if (!form.name) errors.name = 'Le nom est requis'
      break
    case 'email':
      if (!form.email) {
        errors.email = 'L\'email est requis'
      } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(form.email)) {
        errors.email = 'Veuillez entrer une adresse email valide'
      }
      break
    case 'subject':
      if (!form.subject) errors.subject = 'Le sujet est requis'
      break
    case 'message':
      if (!form.message) errors.message = 'Le message est requis'
      break
  }
}

const isFormValid = computed(() => {
  return form.name && form.email && form.subject && form.message && form.privacy &&
         !errors.name && !errors.email && !errors.subject && !errors.message
})

const submitForm = async () => {
  Object.keys(form).forEach(validateField)
  if (!isFormValid.value) return

  isSubmitting.value = true
  
  try {
    // Simuler un appel API pour envoyer l'email
    await new Promise(resolve => setTimeout(resolve, 2000))
    
    // Simuler l'envoi d'un email (dans un vrai scénario, cela serait géré côté serveur)
    console.log(`Email envoyé à info@vivrelarochelle.fr avec les détails suivants:
      Nom: ${form.name}
      Email: ${form.email}
      Sujet: ${form.subject}
      Message: ${form.message}
    `)
    
    submitStatus.value = 'success'
    statusMessage.value = 'Votre message a été envoyé avec succès. Nous vous répondrons dans les plus brefs délais.'
    // Réinitialiser le formulaire
    Object.keys(form).forEach(key => form[key] = '')
  } catch (error) {
    submitStatus.value = 'error'
    statusMessage.value = 'Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer plus tard.'
  } finally {
    isSubmitting.value = false
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&family=Lato:wght@300;400;700&display=swap');

h1, h2 {
  font-family: 'Playfair Display', serif;
}

body {
  font-family: 'Lato', sans-serif;
}

.aspect-w-16 {
  position: relative;
  padding-bottom: 56.25%;
}

.aspect-w-16 iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
</style>
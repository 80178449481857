// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

// Import components for main routes
import HomePage from '@/views/main/HomePage.vue'
import AboutPage from '@/views/main/AboutPage.vue'
import ContactPage from '@/views/main/ContactPage.vue'
import ServicesPage from '@/views/main/ServicesPage.vue'
import LoginPage from '@/views/auth/LoginPage.vue'
import PrivacyPolicy from '@/views/legal/PrivacyPolicy.vue'
import TermsOfService from '@/views/legal/TermsOfService.vue'
import PageDecouvrir from '@/views/discover/PageDecouvrir.vue'
import NotFound from '@/views/errors/NotFound.vue'

// Lazy-loaded components
const GalleryPage = () => import('@/views/media/GalleryPage.vue')
const AdminDashboard = () => import('@/views/admin/AdminDashboard.vue')
const AlbumList = () => import('@/views/admin/AlbumList.vue')
const AlbumDetailPage = () => import('@/views/media/AlbumDetailPage.vue')
const UserManagementPage = () => import('@/views/admin/UserManagementPage.vue')
const MediaManagementPage = () => import('@/views/admin/MediaManagementPage.vue')
const CategoryTagManagementPage = () => import('@/views/admin/CategoryTagManagementPage.vue')
const CommentManagementPage = () => import('@/views/admin/CommentManagementPage.vue')
const StatisticsPage = () => import('@/views/admin/StatisticsPage.vue')
const SettingsPage = () => import('@/views/admin/SettingsPage.vue')
const ActualitesLocales = () => import('@/views/local-life/ActualitesLocales.vue')
const SitesEmblematiques = () => import('@/views/discover/SitesEmblematiques.vue')
const ActivitesPleinAir = () => import('@/views/discover/ActivitesPleinAir.vue')
const RestaurantsCafes = () => import('@/views/local-life/RestaurantsCafes.vue')
const BoutiquesLocales = () => import('@/views/local-life/BoutiquesLocales.vue')
const TransportsPage = () => import('@/views/transport/TransportsPage.vue')
const EspacesVertsPlages = () => import('@/views/local-life/EspacesVertsPlages.vue')
const CalendrierEvenements = () => import('@/views/events/CalendrierEvenements.vue')
const ForumPage = () => import('@/views/community/ForumPage.vue')

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: { title: 'Accueil - VIVRE LA ROCHELLE' }
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage,
    alias: '/a-propos',
    meta: { title: 'À propos - VIVRE LA ROCHELLE' }
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: { title: 'Connexion - VIVRE LA ROCHELLE' }
  },
  {
    path: '/services',
    name: 'ServicesPage',
    component: ServicesPage,
    meta: { title: 'Nos Services - VIVRE LA ROCHELLE' }
  },
  {
    path: '/gallery',
    name: 'GalleryPage',
    component: GalleryPage,
    meta: { title: 'Galerie - VIVRE LA ROCHELLE' }
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage,
    meta: { title: 'Contact - VIVRE LA ROCHELLE' }
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: { title: 'Politique de confidentialité - VIVRE LA ROCHELLE' }
  },
  {
    path: '/terms',
    name: 'TermsOfService',
    component: TermsOfService,
    meta: { title: 'Conditions d\'utilisation - VIVRE LA ROCHELLE' }
  },
  {
    path: '/decouvrir',
    name: 'PageDecouvrir',
    component: PageDecouvrir,
    meta: { title: 'Découvrir La Rochelle - VIVRE LA ROCHELLE' }
  },
  {
    path: '/actualites-locales',
    name: 'ActualitesLocales',
    component: ActualitesLocales,
    meta: { title: 'Actualités Locales - VIVRE LA ROCHELLE' }
  },
  {
    path: '/sites-emblematiques',
    name: 'SitesEmblematiques',
    component: SitesEmblematiques,
    meta: { title: 'Sites Emblématiques - VIVRE LA ROCHELLE' }
  },
  {
    path: '/activites-plein-air',
    name: 'ActivitesPleinAir',
    component: ActivitesPleinAir,
    meta: { title: 'Activités de Plein Air - VIVRE LA ROCHELLE' }
  },
  {
    path: '/restaurants-cafes',
    name: 'RestaurantsCafes',
    component: RestaurantsCafes,
    meta: { title: 'Restaurants et Cafés - VIVRE LA ROCHELLE' }
  },
  {
    path: '/boutiques-locales',
    name: 'BoutiquesLocales',
    component: BoutiquesLocales,
    meta: { title: 'Boutiques Locales - VIVRE LA ROCHELLE' }
  },
  {
    path: '/transports',
    name: 'TransportsPage',
    component: TransportsPage,
    meta: { title: 'Transports - VIVRE LA ROCHELLE' }
  },
  {
    path: '/espaces-verts-plages',
    name: 'EspacesVertsPlages',
    component: EspacesVertsPlages,
    meta: { title: 'Espaces Verts et Plages - VIVRE LA ROCHELLE' }
  },
  {
    path: '/calendrier-evenements',
    name: 'CalendrierEvenements',
    component: CalendrierEvenements,
    meta: { title: 'Calendrier des Événements - VIVRE LA ROCHELLE' }
  },
  {
    path: '/forum',
    name: 'ForumPage',
    component: ForumPage,
    meta: { title: 'Forum - VIVRE LA ROCHELLE' }
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: { requiresAuth: true, requiresAdmin: true, title: 'Administration - VIVRE LA ROCHELLE' },
    children: [
      {
        path: 'albums',
        name: 'AlbumList',
        component: AlbumList,
        meta: { title: 'Liste des Albums - Administration' }
      },
      {
        path: 'albums/:id(\\d+)',
        name: 'AlbumDetailPage',
        component: AlbumDetailPage,
        props: true,
        meta: { title: 'Détails de l\'Album - Administration' },
        beforeEnter: async (to, from, next) => {
          try {
            const albumExists = await store.dispatch('checkAlbumExists', to.params.id)
            if (albumExists) {
              next()
            } else {
              next({ name: 'NotFound' })
            }
          } catch (error) {
            console.error('Erreur lors de la vérification de l\'album:', error)
            next({ name: 'NotFound' })
          }
        }
      },
      {
        path: 'users',
        name: 'UserManagementPage',
        component: UserManagementPage,
        meta: { title: 'Gestion des Utilisateurs - Administration' }
      },
      {
        path: 'media',
        name: 'MediaManagementPage',
        component: MediaManagementPage,
        meta: { title: 'Gestion des Médias - Administration' }
      },
      {
        path: 'categories-tags',
        name: 'CategoryTagManagementPage',
        component: CategoryTagManagementPage,
        meta: { title: 'Gestion des Catégories et Tags - Administration' }
      },
      {
        path: 'comments',
        name: 'CommentManagementPage',
        component: CommentManagementPage,
        meta: { title: 'Gestion des Commentaires - Administration' }
      },
      {
        path: 'statistics',
        name: 'StatisticsPage',
        component: StatisticsPage,
        meta: { title: 'Statistiques - Administration' }
      },
      {
        path: 'settings',
        name: 'SettingsPage',
        component: SettingsPage,
        meta: { title: 'Paramètres - Administration' }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { title: 'Page non trouvée - VIVRE LA ROCHELLE' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0, behavior: 'smooth' }
    }
  }
})

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || 'VIVRE LA ROCHELLE'

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      try {
        await store.dispatch('checkAuth')
      } catch (error) {
        console.error('Erreur lors de la vérification de l\'authentification:', error)
        next({
          name: 'LoginPage',
          query: { redirect: to.fullPath }
        })
        return
      }
    }
    
    if (!store.getters.isAuthenticated) {
      next({
        name: 'LoginPage',
        query: { redirect: to.fullPath }
      })
    } else if (to.matched.some(record => record.meta.requiresAdmin)) {
      if (store.getters.isAdmin) {
        next()
      } else {
        next({ name: 'HomePage' })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

router.onError((error) => {
  console.error('Erreur de routage:', error)
})

export default router
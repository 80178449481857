<template>
  <div class="min-h-screen bg-black text-white">
    <!-- Hero Section -->
    <div class="relative overflow-hidden bg-black">
      <div class="absolute inset-0">
        <img src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/quai-dupere-la-rochelle-pietonnisation-1200x640-1805411948-IrTUQMADl5YK5bqi0TPC0rTYxncgTw.jpeg" alt="La Rochelle" class="w-full h-full object-cover opacity-50" />
        <div class="absolute inset-0 bg-gradient-to-r from-black via-black to-transparent"></div>
      </div>
      <div class="relative max-w-7xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
        <div class="md:ml-auto md:w-1/2 md:pl-10">
          <div class="relative">
            <div class="absolute -left-16 -top-16 w-32 h-32 bg-yellow-400 rounded-full opacity-50 blur-xl"></div>
            <div class="absolute -right-16 -bottom-16 w-32 h-32 bg-yellow-400 rounded-full opacity-50 blur-xl"></div>
            <h1 class="text-4xl font-bold font-serif tracking-tight sm:text-5xl md:text-6xl">
              <span class="block">Vivre</span>
              <span class="block text-yellow-400">La Rochelle</span>
            </h1>
            <p class="mt-3 text-lg text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl font-sans">
              Capturez l'essence de notre ville à travers l'objectif
            </p>
            <div class="mt-8 flex space-x-4">
              <router-link to="/decouvrir" class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-black bg-yellow-400 hover:bg-yellow-500 transition duration-300">
                Découvrir la ville
              </router-link>
              <router-link to="/actualites-locales" class="inline-flex items-center px-6 py-3 border border-yellow-400 text-base font-medium rounded-md text-yellow-400 bg-transparent hover:bg-yellow-400 hover:text-black transition duration-300">
                Actualités
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="absolute bottom-0 left-0 w-full h-16 bg-gradient-to-t from-black to-transparent"></div>
    </div>

    <main class="container mx-auto px-4 py-8">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <div class="bg-gray-900 rounded-lg shadow-md overflow-hidden">
          <img src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/quai-dupere-la-rochelle-pietonnisation-1200x640-1805411948-IrTUQMADl5YK5bqi0TPC0rTYxncgTw.jpeg" alt="La Rochelle" class="w-full h-64 object-cover" />
          <div class="p-6">
            <h2 class="text-2xl font-serif font-semibold mb-4 text-yellow-400">Découvrez notre ville</h2>
            <p class="text-gray-300 mb-4">
              La Rochelle, joyau de la côte atlantique, vous invite à explorer son riche patrimoine, 
              ses plages magnifiques et sa culture vibrante.
            </p>
            <router-link to="/decouvrir" class="inline-block px-4 py-2 bg-yellow-400 text-black rounded-md hover:bg-yellow-500 transition duration-300">
              En savoir plus
            </router-link>
          </div>
        </div>
        
        <div class="bg-gray-900 rounded-lg shadow-md overflow-hidden">
          <img src="https://images.unsplash.com/photo-1589556264800-08ae9e129a8c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80" alt="Événements à La Rochelle" class="w-full h-64 object-cover" />
          <div class="p-6">
            <h2 class="text-2xl font-serif font-semibold mb-4 text-yellow-400">Événements à venir</h2>
            <p class="text-gray-300 mb-4">
              Ne manquez aucun des événements passionnants qui animent notre ville tout au long de l'année.
            </p>
            <router-link to="/calendrier-evenements" class="inline-block px-4 py-2 bg-yellow-400 text-black rounded-md hover:bg-yellow-500 transition duration-300">
              Voir le calendrier
            </router-link>
          </div>
        </div>
      </div>
      
      <div class="bg-gray-900 rounded-lg shadow-md overflow-hidden p-6 mb-12">
        <h2 class="text-2xl font-serif font-semibold mb-4 text-yellow-400">Actualités récentes</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div v-for="(news, index) in recentNews" :key="index" class="border-b border-gray-700 pb-4 mb-4 last:border-b-0 last:pb-0 last:mb-0">
            <h3 class="text-lg font-serif font-semibold mb-2 text-white">{{ news.title }}</h3>
            <p class="text-gray-300 mb-2">{{ news.description }}</p>
            <router-link :to="`/actualites-locales/${news.id}`" class="text-yellow-400 hover:text-yellow-500 transition duration-300">
              Lire la suite
            </router-link>
          </div>
        </div>
        <div class="mt-6 text-center">
          <router-link to="/actualites-locales" class="inline-block px-6 py-3 bg-yellow-400 text-black rounded-md hover:bg-yellow-500 transition duration-300">
            Toutes les actualités
          </router-link>
        </div>
      </div>
      
      <div class="bg-gray-900 rounded-lg shadow-md overflow-hidden p-6">
        <h2 class="text-2xl font-serif font-semibold mb-4 text-yellow-400">Vie pratique</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <router-link 
            v-for="(item, index) in practicalInfo" 
            :key="index" 
            :to="item.route"
            class="flex items-center p-4 bg-black rounded-lg hover:bg-gray-800 transition duration-300"
          >
            <component :is="item.icon" class="w-8 h-8 mr-3 text-yellow-400" />
            <div>
              <h3 class="font-serif font-semibold text-white">{{ item.title }}</h3>
              <p class="text-sm text-gray-300">{{ item.description }}</p>
            </div>
          </router-link>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Bus, Recycle, School, Phone } from 'lucide-vue-next'

const recentNews = ref([
  {
    id: 1,
    title: "Record de fréquentation pour l'Aquarium",
    description: "L'Aquarium de La Rochelle bat tous les records avec plus de 850 000 visiteurs en 2023."
  },
  {
    id: 2,
    title: "Nouveau parcours cyclable",
    description: "La ville inaugure une nouvelle piste cyclable reliant le centre-ville aux plages."
  },
  {
    id: 3,
    title: "Festival de musique au Vieux-Port",
    description: "Le Vieux-Port s'anime avec un festival de musique gratuit tout l'été."
  }
])

const practicalInfo = ref([
  {
    icon: Bus,
    title: "Transports",
    description: "Infos sur les bus et vélos en libre-service",
    route: "/transports"
  },
  {
    icon: Recycle,
    title: "Recyclage",
    description: "Points de collecte et conseils de tri",
    route: "/recyclage"
  },
  {
    icon: School,
    title: "Éducation",
    description: "Écoles et services périscolaires",
    route: "/education"
  },
  {
    icon: Phone,
    title: "Urgences",
    description: "Numéros utiles et services d'urgence",
    route: "/urgences"
  }
])
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Playfair+Display:wght@400;700&display=swap');

:deep(body) {
  font-family: 'Lato', sans-serif;
}

:deep(h1), :deep(h2), :deep(h3) {
  font-family: 'Playfair Display', serif;
}

.blur-xl {
  filter: blur(40px);
}
</style>
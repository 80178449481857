<template>
    <div class="relative">
      <input
        :type="type"
        :value="modelValue"
        @input="handleInput"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="[
          'w-full px-4 py-2 border rounded-md transition-colors duration-200',
          'focus:outline-none focus:ring-2 focus:ring-primary',
          { 'bg-muted text-muted-foreground': disabled, 'bg-background text-foreground': !disabled }
        ]"
      />
      <label
        v-if="label"
        :class="[
          'absolute left-2 transition-all duration-200',
          { '-top-2.5 text-xs bg-background px-1': isLabelFloating, 'top-2 text-muted-foreground': !isLabelFloating }
        ]"
      >
        {{ label }}
      </label>
    </div>
  </template>
  
  <script setup>
  import { computed } from 'vue'
  
  const props = defineProps({
    modelValue: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  })
  
  const emit = defineEmits(['update:modelValue'])
  
  const isLabelFloating = computed(() => {
    return !!props.modelValue || document.activeElement === event?.target
  })
  
  const handleInput = (event) => {
    emit('update:modelValue', event.target.value)
  }
  </script>
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import './styles/globals.css'
import './styles/tailwind.css'
const app = createApp(App)
const pinia = createPinia()

// Use plugins
app.use(router)
app.use(pinia)

// Global error handling
app.config.errorHandler = (err, vm, info) => {
  console.error('Global error:', err)
  console.error('Vue instance:', vm)
  console.error('Error info:', info)
  // You could also send this error to an error tracking service
  // Example: sendToErrorTrackingService(err, vm, info)
}

// Global warning handler
app.config.warnHandler = (msg, vm, trace) => {
  console.warn('Global warning:', msg)
  console.warn('Vue instance:', vm)
  console.warn('Trace:', trace)
}

// Performance measuring in development
if (process.env.NODE_ENV === 'development') {
  app.config.performance = true
}

// Global properties
app.config.globalProperties.$filters = {
  capitalize(value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
  },
  formatDate(value) {
    if (!value) return ''
    const date = new Date(value)
    return date.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' })
  },
  formatCurrency(value) {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(value)
  }
}

// Custom directives
app.directive('focus', {
  mounted(el) {
    el.focus()
  }
})

app.directive('click-outside', {
  mounted(el, binding) {
    el.clickOutsideEvent = function(event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el)
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideEvent)
  }
})

// Global components
import BaseButton from './components/BaseButton.vue'
import BaseInput from './components/BaseInput.vue'

app.component('BaseButton', BaseButton)
app.component('BaseInput', BaseInput)

// Mount the app
app.mount('#app')
<template>
  <div class="min-h-screen bg-black text-white">
    <div class="container mx-auto px-4 py-8">
      <h1 class="text-4xl font-bold font-serif mb-8 text-yellow-400">Découvrir La Rochelle</h1>
      
      <div class="mb-12">
        <p class="text-lg mb-4">
          Bienvenue à La Rochelle, joyau de la côte atlantique française. Avec son riche patrimoine maritime, 
          son architecture préservée et sa douceur de vivre, La Rochelle vous invite à un voyage inoubliable 
          entre histoire et modernité.
        </p>
        <p class="text-lg">
          Explorez nos principales attractions et laissez-vous séduire par le charme unique de notre ville.
        </p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        <div v-for="attraction in attractions" :key="attraction.id" class="bg-gray-900 rounded-lg overflow-hidden shadow-lg transition-all duration-300 hover:shadow-yellow-400/20">
          <img 
            :src="attraction.image" 
            :alt="attraction.name" 
            class="w-full h-64 object-cover"
            @error="handleImageError"
          />
          <div class="p-6">
            <h2 class="text-2xl font-serif font-semibold mb-2 text-yellow-400">{{ attraction.name }}</h2>
            <p class="text-gray-300 mb-4">{{ attraction.description }}</p>
            <button 
              @click="toggleExpanded(attraction.id)"
              class="flex items-center text-yellow-400 hover:text-yellow-500 transition duration-300"
            >
              <span v-if="expandedAttraction === attraction.id">
                Moins d'infos
                <ChevronUp class="ml-2 h-4 w-4" />
              </span>
              <span v-else>
                Plus d'infos
                <ChevronDown class="ml-2 h-4 w-4" />
              </span>
            </button>
            <p v-if="expandedAttraction === attraction.id" class="mt-4 text-gray-400 animate-fadeIn">{{ attraction.details }}</p>
          </div>
        </div>
      </div>

      <div class="mb-12">
        <h2 class="text-2xl font-serif font-semibold mb-4 text-yellow-400">Carte des Attractions</h2>
        <div class="bg-gray-900 p-4 rounded-lg">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d44803.31791578688!2d-1.1853427!3d46.1588184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48015383c9253d75%3A0x405d39260ee9640!2sLa%20Rochelle%2C%20France!5e0!3m2!1sen!2sus!4v1652893681896!5m2!1sen!2sus"
            width="100%"
            height="450"
            style="border:0;"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div class="text-center">
        <p class="text-lg mb-4">
          Prêt à explorer La Rochelle ? Venez découvrir par vous-même toutes les merveilles que notre ville a à offrir !
        </p>
        <button class="px-6 py-3 bg-yellow-400 text-black rounded-md hover:bg-yellow-500 transition duration-300">
          Planifier votre visite
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { ChevronDown, ChevronUp } from 'lucide-vue-next'

const expandedAttraction = ref(null)

const toggleExpanded = (id) => {
  expandedAttraction.value = expandedAttraction.value === id ? null : id
}

const handleImageError = (event) => {
  event.target.src = '/placeholder.svg?height=300&width=400'
}

const attractions = [
  {
    id: 1,
    name: "Vieux-Port",
    description: "Le cœur historique de La Rochelle, entouré de belles arcades et de restaurants.",
    image: "https://images.unsplash.com/photo-1589794472494-b1ff8e1e4b2b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
    details: "Le Vieux-Port de La Rochelle est l'un des plus beaux et des mieux préservés de France. Flanqué de ses trois tours emblématiques - la Tour de la Chaîne, la Tour Saint-Nicolas et la Tour de la Lanterne - il offre un panorama spectaculaire, en particulier au coucher du soleil. Les visiteurs peuvent flâner le long des quais, admirer les bateaux de plaisance et profiter des nombreux cafés et restaurants qui bordent le port."
  },
  {
    id: 2,
    name: "Aquarium de La Rochelle",
    description: "L'un des plus grands aquariums privés d'Europe, abritant plus de 12 000 animaux marins.",
    image: "https://images.unsplash.com/photo-1584269655525-c2db9d3e5e1f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
    details: "L'Aquarium de La Rochelle est une véritable immersion dans le monde marin. Avec ses 3 millions de litres d'eau et ses 12 000 animaux représentant 600 espèces différentes, il offre un voyage fascinant à travers les océans du monde. Les visiteurs peuvent observer des requins, des tortues de mer, des méduses et bien d'autres créatures marines dans des environnements soigneusement reconstitués."
  },
  {
    id: 3,
    name: "Les Tours de La Rochelle",
    description: "Trois tours médiévales emblématiques qui gardent l'entrée du Vieux-Port.",
    image: "https://images.unsplash.com/photo-1589794406692-7cb50cae9c33?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
    details: "Les Tours de La Rochelle sont les gardiens historiques de la ville. La Tour Saint-Nicolas et la Tour de la Chaîne, qui encadrent l'entrée du port, ainsi que la Tour de la Lanterne, offrent une plongée fascinante dans l'histoire médiévale de la ville. Les visiteurs peuvent monter au sommet des tours pour profiter d'une vue panoramique sur la ville et l'océan."
  },
  {
    id: 4,
    name: "Plage des Minimes",
    description: "La plus grande plage de La Rochelle, idéale pour la baignade et les sports nautiques.",
    image: "https://images.unsplash.com/photo-1621789098261-430d7d269cf6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
    details: "La plage des Minimes est le lieu idéal pour profiter du soleil et de la mer à La Rochelle. Avec son sable fin et ses eaux calmes, elle est parfaite pour la baignade et le farniente. La plage est également un point de départ pour de nombreuses activités nautiques telles que la voile, le paddle et le kayak. Le long de la promenade, on trouve de nombreux restaurants et glaciers pour se rafraîchir."
  },
  {
    id: 5,
    name: "Marché Central",
    description: "Un marché couvert animé offrant des produits locaux frais et des spécialités régionales.",
    image: "https://images.unsplash.com/photo-1595506071728-8e31d2f73a2e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1000&q=80",
    details: "Le Marché Central de La Rochelle est un véritable festin pour les sens. Situé dans un bâtiment du 19ème siècle, ce marché couvert regorge de stands colorés proposant des produits frais de la région. On y trouve des fruits de mer fraîchement pêchés, des fromages locaux, des fruits et légumes de saison, ainsi que des spécialités charentaises. C'est l'endroit idéal pour goûter à la gastronomie locale et s'imprégner de l'atmosphère animée de la ville."
  }
]
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Playfair+Display:wght@400;700&display=swap');

body {
  font-family: 'Lato', sans-serif;
}

h1, h2, h3 {
  font-family: 'Playfair Display', serif;
}

.animate-fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>